<template>
  <div class="Lobby_Container">
    <Panorama
      v-if="loaded"
      :data="data"
      :imageURL="GetImageURL(this.data.image)"
      :subtitles="true"
      :module="'lobby'"
      @PinClicked="PinSelected($event)"
    />
    <div class="SideMenuContainer">
      <PanelButton
        class="MapButton"
        @Clicked="$store.dispatch('sidePanel/SetMapOpen', true)"
      />
      <SidePanel />
    </div>
    <InfiniteLoader :color="'0,0,0'" v-show="!loaded" class="Lobby__Loader" />
  </div>
</template>

<script>
import InfiniteLoader from '@/components/loader/InfiniteLoader.vue'
import PanelButton from '@/components/panelButton/PanelButton.vue'
import SidePanel from '@/components/sidePanel/SidePanel.vue'
import { GetJSON } from '@/utils/filesManager'
import Panorama from '@/components/panorama/Panorama.vue'

export default {
  data () {
    return {
      loaded: false,
      data: ''
    }
  },
  components: {
    InfiniteLoader,
    Panorama,
    PanelButton,
    SidePanel
  },
  async mounted () {
    await this.loadData()
  },
  computed: {
    wording () {
      return this.$store.getters['langs/wording']
    }
  },
  methods: {
    async loadData () {
      try {
        this.data = await GetJSON(
          this.$store.getters['events/getEvents'].default.interfaceContent.lobby
            .data.media['en-US'][0].objects.original
        )
      } catch (e) {
        console.error(e)
      } finally {
        this.loaded = true
      }
    },
    PinSelected (id) {
      if (id === 'relaxation') {
        this.$router.push({ name: 'pantelleria' })
      } else if (id === 'showroom') {
        this.$router.push({ name: 'showroom' })
      } else if (id === 'sustainability') {
        this.$router.push({ name: 'sustainability' })
      } else if (id === 'conference') {
        this.$router.push({ name: 'conference' })
      }
    },
    GetImageURL (name) {
      const wording = this.$store.getters['langs/wording']
      return wording.lobby[name].media['en-US'][0].objects.original
    }
  }
}
</script>

<style scoped lang="scss">
.Lobby_Container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
}

.Lobby__Loader {
  margin: auto;
  width: 50px;
  height: 50px;
  align-self: center;
}

.SideMenuContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  pointer-events: none;
  z-index: 10;
}

.MapButton {
  pointer-events: auto;
}
</style>
